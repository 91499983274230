@font-face {
  font-family: "icomoon";
  src: url("../assets/fonts/icomoon.woff?xtlh90") format("woff"),
    url("../assets/fonts/icomoon.eot?xtlh90#iefix") format("embedded-opentype"),
    url("../assets/fonts/icomoon.ttf?xtlh90") format("truetype"),
    url("../assets/fonts/icomoon.svg?xtlh90#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="iconeq-"],
[class*=" iconeq-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  float: left;
  line-height: 1;
  font-size: 1.4em;
  margin-right: 12px;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconeq-boisko-01:before {
  content: "\20";
}
.iconeq-spa-01:before {
  content: "\e904";
}
.iconeq-sala_zabaw-01:before {
  content: "\e905";
}
.iconeq-karta-01:before {
  content: "\e906";
}
.iconeq-balkon-01-01:before {
  content: "\e907";
}
.iconeq-basen-01:before {
  content: "\e908";
}
.iconeq-bilard-01:before {
  content: "\e909";
}
.iconeq-czajnik-01:before {
  content: "\e90a";
}
.iconeq-dla_dzieci-01:before {
  content: "\e90b";
}
.iconeq-grill-01:before {
  content: "\e90c";
}
.iconeq-klimatyzacja-01:before {
  content: "\e90d";
}
.iconeq-kuchnia-01:before {
  content: "\e90e";
}
.iconeq-lazienka-01:before {
  content: "\e90f";
}
.iconeq-lodowka-01:before {
  content: "\e910";
}
.iconeq-obiad-01:before {
  content: "\e911";
}
.iconeq-ognisko-01:before {
  content: "\e912";
}
.iconeq-ogrod-01:before {
  content: "\e913";
}
.iconeq-parking-01:before {
  content: "\e914";
}
.iconeq-pingpong-01:before {
  content: "\e915";
}
.iconeq-plac_zabaw-01:before {
  content: "\e916";
}
.iconeq-radio-01:before {
  content: "\e917";
}
.iconeq-reczniki-01:before {
  content: "\e918";
}
.iconeq-rower-01:before {
  content: "\e919";
}
.iconeq-satelita-01:before {
  content: "\e91a";
}
.iconeq-sauna-01:before {
  content: "\e91b";
}
.iconeq-sejf:before {
  content: "\e91c";
}
.iconeq-silownia-01:before {
  content: "\e91d";
}
.iconeq-sprzet_plazowy-01:before {
  content: "\e91e";
}
.iconeq-suszarka-01:before {
  content: "\e91f";
}
.iconeq-sztucce:before {
  content: "\e920";
}
.iconeq-telewizor-01:before {
  content: "\e921";
}
.iconeq-termometr-01:before {
  content: "\e922";
}
.iconeq-wifi-01:before {
  content: "\e923";
}
.iconeq-wyzywienie-01:before {
  content: "\e924";
}
.iconeq-zmywarka-01:before {
  content: "\e925";
}
.iconeq-zwierzeta-01:before {
  content: "\e926";
}
.iconeq-parking-012:before {
  content: "\e927";
}
.iconeq-bike-01:before {
  content: "\e928";
}
.iconeq-caloroczny-01:before {
  content: "\e900";
}
.iconeq-iron-01:before {
  content: "\e901";
}
.iconeq-disabled-01:before {
  content: "\e902";
}
.iconeq-tourist-voucher:before {
  content: "\e903";
}
