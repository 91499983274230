// Here you can add other styles

body {
  font-size: 0.8rem;
}

a {
  color: $color-akcept;

  &:hover {
    color: darken($color-akcept, 20);
  }
}

.card-img-top.object_photo {
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.card-img-top.object_photo_small {
  height: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.card-accent-primary {
  border-top-color: $color-akcept;
}

.main {
  @media (max-width: 768px) {
    .container-fluid {
      padding: 0 15px;
    }
  }
}

.bg--akcept {
  background-color: $color-akcept;

  &.progress {
    &-bar {
      background-color: $color-akcept;
    }
  }
}

.progress {
  &-bar {
    background-color: $color-akcept;
  }
}

h1,
.h1 {
  font-weight: 300;
}

.navbar-brand {
  @media (min-width: 768px) {
    padding-left: 10px;
  }
}

.nav-item {
  &__user-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    line-height: 1.2;
    border: 1px solid $color-akcept;

    @media (min-width: 768px) {
      border: none;
      padding: 0.25rem 0.5rem;
    }

    .icon-user {
      font-size: 1.5rem;

      @media (min-width: 768px) {
        margin-right: 0.4rem;
      }
    }
  }

  &__dropdownmenu {
    border-top: 2px solid $color-akcept;
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
  }
}

.card-manager {
  text-align: center;

  &__photo {
    width: 100%;
    height: 100%;
    border-radius: 50%;

    @media (min-width: 1400px) {
      width: 80%;
      height: 80%;
    }
  }

  &__wrapper {
    @media (max-width: 767px) {
      display: none;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      width: calc(100vw - 30px);
      box-shadow: 3px 0px 10px rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      border: 1px solid $color-akcept;
    }
  }

  &__title {
    @media (max-width: 767px) {
      font-size: 1.5rem;
    }
  }

  &--active {
    display: block;
  }

  &__btn {
    display: block;
    position: fixed;
    bottom: 10px;
    left: 10px;
    border-radius: 50%;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.5);
    font-size: 20px;
    padding: 4px;
    width: 40px;
    height: 40px;
    line-height: 1;
    background-color: $color-akcept;
    color: white;
    border-color: $color-akcept;

    @media (min-width: 768px) {
      display: none;
    }
  }
}

.notes {
  position: relative;

  &--open {
	position: fixed;
    right: 50px;
    z-index: 100;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: $color-akcept;
    color: #fff;
    border-radius: 50%;
    position: relative;

    i {
      font-size: 20px;
    }

    .badge {
      position: absolute;
      top: auto;
      bottom: -3px;
      right: 0;
    }
	&--warning {
		background-color: $color-warning;
	}
  }

  &__cloud {
    position: absolute;
    left: auto;
    top: 50px;
    right: -15px;
    width: 300px;
    border-top: 5px solid lighten($color-akcept, 20);
    background: #fff;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
    z-index: 1;
	@media (min-width: 992px) {
		width: 450px;
	}
	@media (min-width: 1200px) {
		width: 650px;
	}
  }

  &__add {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  &__textarea {
    width: 100%;
    font-size: 0.7rem;
    padding-right: 5px;

    .ql-container {
      font-family: inherit;
    }

    .ql-editor {
      padding: 3px;
      border: 1px solid $color-gray-light;
      line-height: 1.1;
    }
  }

  &__feelings {
    display: flex;
    flex-direction: column;
  }

  &__items {
    padding-left: 0;
    max-height: 300px;
    overflow: auto;
    margin-bottom: 0.5rem;
  }

  &__item {
    list-style-type: none;

    &--negative {
      background-color: lighten($color-akcept, 30);
      padding: 5px;
    }

    p {
      line-height: 1;
      margin-bottom: 0.5rem;
    }
  }

  &__footer {
    background-color: #eee;
    font-size: 0.7rem;
    padding: 5px;
  }

  &__from {
    font-style: italic;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    font-size: 0.7rem;
  }
}

.notes-table {
  .notes {
    &__items {
      max-height: none;
    }

    &__item {
      padding: 10px;
      border-bottom: 1px solid #eee;
    }
  }
}

.notification-list {
  &__items {
    padding-left: 0;
  }

  &__item {
    border-bottom: 1px solid #eee;
    padding-bottom: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.7rem;
  }
}

.table-admin-objects {
  &__name {
    max-width: 400px;
  }

  &__stats {
    min-width: 180px;
  }

  &__edit {
    min-width: 200px;
  }

  &__services-list {
    margin-bottom: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 300;
}

.col-sm-6 .select-search-box {
  width: auto;

  &__search {
    box-shadow: none;
    border: 1px solid #e4e7ea;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
  }
}

.tour {
  &__btn {
    position: fixed;
    bottom: 10px;
    right: 10px;
    border-radius: 50%;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.5);
    font-size: 25px;
    padding: 4px;
    width: 40px;
    height: 40px;
    line-height: 1;
  }
}

.table-columns {
  @media only screen and (max-width: 767px) {
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border: 1px solid #ccc;
      margin-top: 5px;
    }

    td {
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      white-space: normal;
      text-align: right;

      &:before {
        position: absolute;
        top: 0;
        left: 4px;
        font-size: 10px;
        color: #ccc;
        white-space: nowrap;
        text-align: left;
        content: attr(data-title);
      }

      ul {
        margin-bottom: 0;
      }
    }
  }
}

.objects {
  &__btns {
    display: flex;

    .btn {
      margin-right: 3px;
    }

    @media (max-width: 767px) {
      flex-direction: column;

      .btn {
        margin-bottom: 3px;
      }
    }
  }
}

.filters {
  .form-group {
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  .select-search-box {
    width: 100%;
    margin-bottom: 0;
    padding-left: 0.75rem;

    &:after {
      line-height: 34px;
    }

    &__search {
      box-shadow: none;
      height: 34px;
      font-family: inherit;
      padding: 0;

      &::placeholder {
        font-style: normal;
        color: #73818f;
      }
    }

    &__select {
      top: 44px;
    }
  }
}

.display-5 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.stats {
  .brand-card-header {
    h3 {
      margin-bottom: 0;
    }
  }
}

.header-page {
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.tab-content {
  background: transparent;
  border: none;

  .tab-pane {
    padding: 1rem 0;
  }
}

.nav-tabs {
  .nav-link {
    &.active {
      border-bottom-color: #c8ced3;
    }
  }
}

.display-5 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.2;
}

.stats {
  .brand-card-header {
    h3 {
      margin-bottom: 0;
    }
  }
}

.object-card {
  @media (min-width: 768px) {
    margin-bottom: 1rem;
  }

  &__img {
    position: relative;
  }

  &__link {
    position: absolute;
    bottom: 10px;
    left: 10px;
  }
}

.card-body {
  .form-check-label {
    padding-left: 30px;
  }
}

.radio-sign__label.form-check-label {
  padding-left: 0;
  cursor: pointer;
}

.checkbox-sign {
  &__label {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    &:hover input ~ .checkmark {
      background-color: #ccc;
    }

    input:checked ~ .checkmark {
      background-color: $color-akcept;
      border: 1px solid $color-akcept;
    }

    .checkmark {
      position: absolute;
      top: 1px;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: #eee;
      border: 1px solid #ccc;
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    input:checked ~ .checkmark:after {
      display: block;
    }

    .checkmark:after {
      left: 5px;
      top: 0px;
      width: 9px;
      height: 13px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
}

.radio-sign {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .checkmark {
      background-color: $color-akcept;
      border: 2px solid $color-akcept;
    }

    &:checked ~ .checkmark:after {
      display: block;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 2px solid $gray-300;
    border-radius: 50%;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }

    &:after {
      left: 6px;
      top: 2px;
      width: 9px;
      height: 13px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }

  &:hover input ~ .checkmark {
    background-color: $gray-300;
    border: 2px solid $gray-500;
  }
}

.service-url {
  &--blocked {
    text-decoration: line-through;
    color: gray;
  }
}

//.filter-active {
//  border: 1px solid $color-akcept;
//  border-radius: 0.25rem;
//}

.contactPhoneActive {
  background-color: rgb(168, 205, 216);
}

.contactPhoneBanActive {
  background-color: rgb(22, 24, 25);
  color: #fff;
}

#mapid {
  height: 400px;
}

.notification-read-icon {
  cursor: pointer;
}

.invoices-summary {
  &__pdf {
    hr {
      margin-top: 0.3rem;
      margin-bottom: 0.3rem;
    }
  }
}

.btn {
  &--primary,
  &--secondary {
    text-transform: uppercase;
    font-weight: 600;
    padding: 0.5rem 2rem;

    &.btn-sm {
      padding: 0.5rem 0.75rem;
    }
  }

  &--primary {
    background: $color-akcept;
    color: white;
    border-color: $color-akcept;
  }

  &--secondary {
    background: $gray-300;
    color: white;
    border-color: $gray-300;
  }
}

.icon {
  width: 20px;
  height: 20px;
}

.table-responsive {
  overflow-x: inherit;
}
