.object-images {

  &__drop-zone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #E4E5E6;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
  }

  &__picture-wrapper {
    max-width: 225px;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    img {
      width: 100%;
      max-height: 110px;
      object-fit: cover;
    }

    button{
      padding: 1px 6px;
    }
  }

  &__galleries-wrapper {
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }
}

.checkbox-picture {

  &__label {
    display: inline;
    position: relative;
    padding-left: 17px !important;
    margin-bottom: 12px;
    cursor: pointer;
    margin-left: 15px;
    font-size: 13px;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    &:hover input ~ .checkmark {
      background-color: #ccc;
    }

    input:checked ~ .checkmark {
      background-color: $color-akcept;
      border: 1px solid $color-akcept;
    }

    .checkmark {
      position: absolute;
      top: 3px;
      left: 0;
      height: 11px;
      width: 11px;
      background-color: #eee;
      border: 1px solid #ccc;
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    input:checked ~ .checkmark:after {
      display: block;
    }

    .checkmark:after {
      left: 2px;
      top: 1px;
      width: 5px;
      height: 5px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}

