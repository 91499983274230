.register {
    .card {
        border-radius: 0;
        border: none;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    }

    .card-body {
        padding: 15px;
        @media (min-width: 768px) {
            padding: 40px;
        }
    }

    .form-group,
    .form-check {
        label {
            color: $gray-600;
        }
    }

    .form-check {
        padding-left: 0;
    }

    &__logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px;

        @media(min-width: 640px) {
            flex-direction: row;
            justify-content: center;
            padding: 20px 40px;
        }
    }

    &__site {
        @media(min-width: 768px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__header {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
    }

    &__header2 {
        font-size: 20px;
        font-weight: 600;
    }

    &__progress {
        background-color: $color-gray-light;
        padding: 15px;

        @media(min-width: 768px) {
            padding: 40px;
        }
		
		.progress, .progress-bar {border-radius: 15px;}

        &-step {
            text-align: center;
            padding: 10px;
            color: $gray-600;

            &.active {
				color: inherit;
				font-weight:bold;
            }
        }
    }

    &__category {
        display: flex;
        flex-wrap: wrap;

        label.radio-sign {
            width: 45%;
            min-height: 100px;
            margin: 2px;
            padding: 3px;
            padding-top: 37px;
            border: 1px solid $gray-200;
            border-radius: 5px;
            font-size: 12px;
            font-weight: 600;
            display: flex;
            justify-content: center;
            padding-left: 0;
            text-align: center;
            line-height: 1;
            color: $color-dark;

            @media (min-width: 360px) {
                width: 32%;
            }

            @media (min-width: 768px) {
                width: 13%;
                font-size: 12px;
                padding: 5px;
                padding-top: 37px;
                margin: 5px;
            }

            .checkmark {
                top: 15px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    &__watermark {
        img {
            width: 100%;
            height: auto;
        }
	}
	&__offers-new-clients {
		background-color: #EBECEE;
		color: #ED1C22;
		padding: 15px;
		text-align: center;
		font-size: 16px;
		p {
			margin:0px;
		}
		strong {
			font-size: 20px;
			text-transform: uppercase;
		}
	}
	&__offers-new-clients-test {
		padding: 15px;
		text-align: center;
		font-size: 16px;
		color: #858585;
		border: 1px solid #EBECEE;
		text-transform: uppercase;
		p {
			margin:0px;
		}
		strong {
			font-size: 24px;
			color: #1A1A1A;
		}
	}

    &__portal {
        &-item {
            text-align: center;
            padding: 20px;
            border: 1px solid $gray-300;
            border-radius: 20px;
            margin-bottom: 20px;

            img {
                width: auto;
                height: 50px;
            }
        }

        &-header {
            font-size: 16px;
            font-weight: 600;
            margin-top: 15px;
        }

        &-icons {
            padding-left: 8px;
            text-align: left;
            margin-bottom: 20px;
            color: $gray-500;

            div {
                display: flex;
                align-items: center;

                .icon {
                    margin-right: 10px;
                }
            }
        }
    }

    &__type {
        position: relative;
        text-align: center;
        padding: 15px;
        border: 1px solid $gray-300;
        border-radius: 5px;
        margin-bottom: 20px;
		&--normal {
			@media(min-width: 768px) {
				min-height: 300px;
			}
			&-footer {
				position: absolute;
				bottom: 0;
			}

		}
		&--tests {
			min-height: 200px;
		}
		&-test {
			font-size: 18px;
    		margin: 0;
		}


		&--premium {
			background-color: #EBEBEB;
		}
		&--selected {
			color: #fff;
			background-color: #20a8d8;
			border-color: #20a8d8;
		}

		@media(min-width: 768px) {
            padding: 45px 30px;
        }

        &-header {
            font-weight: 700;
            font-size: 24px;
            margin-bottom: 20px;
            color: $color-akcept;

            &--standard {
                color: #24282c;
            }
        }

        &-desc {
            text-align: left;
            div {
                position: relative;
                padding-left: 30px;
                margin-bottom: 10px;

                .icon {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 24px;
                    height: 24px;
                }
            }
        }

        &-footer {
            text-align: center;
			width: 100%;
			@media(min-width: 768px) {
				position: absolute;
				bottom: 20px;
				left: 0;
				right: 0;
			}
        }

        &-price {
            font-weight: 700;
            font-size: 18px;

            span {
                font-size: 26px;
            }
        }

		@media(min-width: 768px) {
			min-height: 300px;
			padding-bottom: 30px;
			.register__type-desc {
	
					min-height:170px;
			}
		}
		

	}

    &__summary {
        margin-top: 20px;
        margin-bottom: 30px;

        img {
            height: auto;
            width: 150px;
        }

        &-wrapper {
            border-bottom: 1px solid $gray-300;

            @media(min-width: 768px) {
                width: 75%;
            }
        }

        &-item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            border-radius: 10px;
            border: 1px solid $gray-300;
            padding: 20px;

            @media(min-width: 768px) {
                flex-direction: row;
            }
        }

        &-list {
            display: flex;
            justify-content: space-between;

            .desc {
                color: $gray-600;
            }

            .price {
                font-weight: 600;
            }
		}
		&-test {
			font-size: 16px;	
		}
		&-info-test {
			font-size: 16px;	
		}

        &-header {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 10px;
        }
    }

    &__change-invoice {
        cursor: pointer;
        color: $color-akcept;
        padding: 10px 0;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &__price-summary {
        font-weight: 700;
        font-size: 16px;

        span {
            font-size: 24px;
        }
    }

    &__final {
        &-wrapper {
            text-align: center;
            padding: 30px;
            border: 1px solid $gray-300;
            border-radius: 5px;
        }

        &-info {
            margin-top: 20px;
            margin-bottom: 20px;
            background: $color-akcept;
            padding: 30px;
            color: white;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                font-size: 18px;
                font-weight: 700;
                line-height: 1.3;
            }
        }


        &-btns {

            margin-top: 20px;

            .btn {
                display: block;

                @media (min-width: 768px) {
                    display: inline-block;
                }

                &:first-child {
                    margin-bottom: 5px;

                    @media (min-width: 768px) {
                        margin-right: 5px;
                        margin-bottom: 0;
                    }
                }

                &:last-child {
                    @media (min-width: 768px) {
                        margin-left: 5px;
                    }
                }
            }
        }

        &-icon {
            width: 150px;
            height: 150px;
        }
    }

    &__next-step {
        margin-top: 20px;
        padding-left: 0;

        li {
            position: relative;
            list-style: none;
            font-size: 16px;
            color: $gray-600;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            padding-left: 70px;
            min-height: 50px;

            .icon {
                position: absolute;
                left: 0;
                width: 50px;
                height: 50px;
                background: $color-akcept;
                padding: 10px;
                border-radius: 50px;
                margin-right: 20px;
            }
        }
    }
}

.register-blue {
    .card {
        border-radius: 0;
        border: none;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    }

    .card-body {
        padding: 15px;
        @media (min-width: 768px) {
            padding: 40px;
        }
    }

    &__logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 15px;
		padding-bottom: 15px;
		z-index: 10;
    	position: relative;

        @media(min-width: 768px) {
            flex-direction: row;
            justify-content: center;
			padding-top: 30px;
			padding-bottom: 30px;
			background-image: url('../assets/img/register/mewy.svg');
			background-repeat: no-repeat;
			background-position-x: 80%;
			background-position-y: 20px;
        }

    }

	&__icon {
		background-image: url("../assets/img/register/fala_2.svg");
		background-repeat: no-repeat;
    	background-size: contain;
    	background-position: bottom;
		padding-top:15px;
		padding-bottom:120px;
		.icon-on-top {
			display: flex;
			justify-content: center;
			align-items: center;
			list-style: none;
			padding:0;
			flex-wrap: wrap;
			li {
				width: 170px;
				height: 170px;
				border: 3px solid $color-nm-first;
				border-radius: 10px;
				margin: 2%;
				text-align: center;
				padding: 10px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				background: #fff;
				.svg-inline--fa {
					display: block;
					font-size: 65px;
					height: 1.2em;
					color: $color-nm-second;
					margin: 0px auto;
				}
				div	{
					color: $color-nm-first;
					text-transform: uppercase;
					font-size: 11px;
					font-weight: bold;
				}
			}
		}
	}

	.text-after-icon {
		margin-top: -30px;
		margin-bottom: 85px;
		text-transform: uppercase;
		font-weight: bold;
		text-align: center;
		.first-line {
			color: $color-nm-first;
			font-size: 22px;
			padding-bottom:5px;
		}
		.second-line {
			color: $color-nm-second;
			font-size: 18px;
		}
	}

	&__first-column, &__second-column {
		display: flex;
		align-items: center;

		@media (min-width:992px) {
			width:100%;
		}
	}
	&__first-column{
		border-bottom:2px solid $color-nm-second;
		border-right:2px solid $color-nm-second;
		position: relative;
		font-size: 15px;
	}
	&__second-column {
		border-bottom:2px solid $color-nm-second;
		&.choice-button {
			justify-content: center;
		}
		.row {
			width: 100%;
			margin:0;
			flex-wrap: nowrap;
			&.minus-margin {
				@media (max-width:992px) {
					margin-left:-15px;
					margin-right:-15px;
				}
			}
		}
	}
	&__icon-small-package {
		background-color: $color-nm-second;
		width: 75px;
		min-width: 75px;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		text-align: center;
		margin-left: -15px;
		margin-right: 15px;
		
		border-bottom: 2px solid #fff;
		margin-bottom: -2px;
		
		
			&__first {
				/* top: 2px;
				margin-top: -10px;
				margin-bottom: -10px;
				border-bottom: 2px solid red; */
				img {
					width:50px;
				}
			}

		.svg-inline--fa {
			font-size: 20px;
			height: 1.2em;
			color: #fff;
		}
	}
	&__package-value {
		text-align: center;
		&:nth-child(odd) {
			@media (max-width:992px) {
				border-left:2px solid $color-nm-second;
			}
		}
/* 		&.minus-margin {
			@media (max-width:992px) {
				margin-left: -5px;
				margin-right: -5px;
			}
		} */
		&--head {
			margin-bottom: 15px;
			
			border-left: 2px solid #fff;
			@media (max-width:992px) {
				
			}
		}
		&--head-premium {
			position: relative;
			&::before {
				@media (min-width:992px) {
					content: 'NAJSKUTECZNIEJSZY';
					font-size: 20px;
					color: #FCBE22;
					position: absolute;
					font-weight: bold;
					top: -100px;
					left: 0;
					right: 0;
				}

			}
			&::after {
				@media (min-width:992px) {
					content: '';
					position: absolute;
					left: 42%;
					top: -65px;
					width: 0;
					height: 0;
					border-left: 20px solid transparent;
					border-right: 20px solid transparent;
					border-top: 20px solid #FCBE22;
					clear: both;
				}
			}
			&--on-bottom {
				&::before {
					@media (min-width:992px) {
						content: 'NAJSKUTECZNIEJSZY';
						top: -80px;
					}
	
				}
				&::after {
					@media (min-width:992px) {
						top: -100px;
						border-bottom: 20px solid #FCBE22;
						border-top:0;
					}

				}
			}
		}
		img {
			width: 60px;
			@media (min-width:768px) {
				margin-left: -18px;
			}
		}
	}
	&__package-price {
		background-color: #FCBE22;
		color: #fff;
		text-align: center;
		padding: 5px;
		font-weight: bold;
		font-size:22px;
	} 
	&__head-row {
		background-color: $color-nm-second;
		color: #fff;
		font-size:24px;
		/* &.on-bottom {
			margin-top: 80px;
		} */
	}


	&__head-first-column {
		display: flex;
		justify-content: center;
		align-items: center;
		text-transform: uppercase;
		font-weight:bold;
		@media (max-width:992px) {
			text-align:center;
		}
	}

	&__footer {
		font-size: 16px;
		margin-top: 60px;
		margin-bottom: 45px;
		line-height: 26px;
		a {
			color: #23282c;
			&:hover {
				color: $color-nm-first;
			}
		}
	}

	.btn-add-object {
		color:#fff;
		background-color: $color-nm-first;
		border:0;
		border-radius: 0;
		padding: 15px 30px;
		&:hover {
			border:0;
			background-color: $color-nm-second;
		}
		@media screen and (max-width: 992px) {
			width:100%;
		}
	}

	a {
		color: $color-nm-first;
		&:hover {
			color: $color-nm-second;
		}
	}

	.register-fala-1 {
		position: relative;
		padding-top: 45px;
		padding-bottom: 15px;
		&::after {
			content: "";
			position: absolute;
			background-image: url("../assets/img/register/fala_1.svg");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: top;
			pointer-events: none;
			width: 100%;
			height: 243px;
			left: 0;
			right: 0;
			top: -90px;
			z-index: 0;
		}
		img,h3 {
			position: relative;
			z-index: 1;
		}
	}
	.register-fala-lodka {
		position: relative;
		padding-bottom: 250px;
		&::after {
			content: "";
			position: absolute;
			background-image: url("../assets/img/register/fala-lodka.svg");
			background-repeat: no-repeat;
			background-size: contain;
			background-position: bottom;
			pointer-events: none;
			width: 100%;
			height: 380px;
			left: 0;
			right: 0;
			bottom: 0;
		}	
	}
	.checkbox-sign {
		&__label {
		  display: block;
		  position: relative;
		  padding-left: 30px;
		  margin-bottom: 12px;
		  cursor: pointer;
		  font-size: 16px;
		  user-select: none;
	  
		  input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0;
		  }
	  
		  &:hover input ~ .checkmark {
			background-color: #ccc;
		  }
	  
		  input:checked ~ .checkmark {
			background-color: $color-nm-first;
			border: 1px solid $color-nm-first;
		  }
	  
		  .checkmark {
			position: absolute;
			top: 1px;
			left: 0;
			height: 20px;
			width: 20px;
			background-color: #eee;
			border: 1px solid #ccc;
		  }
	  
		  .checkmark:after {
			content: "";
			position: absolute;
			display: none;
		  }
	  
		  input:checked ~ .checkmark:after {
			display: block;
		  }
	  
		  .checkmark:after {
			left: 5px;
			top: 0px;
			width: 9px;
			height: 13px;
			border: solid white;
			border-width: 0 3px 3px 0;
			transform: rotate(45deg);
		  }
		}
	}
	.radio-sign {
		display: block;
		position: relative;
		padding-left: 35px;
		margin-bottom: 12px;
		cursor: pointer;
		font-size: 16px;
		user-select: none;
	  
		input {
		  position: absolute;
		  opacity: 0;
		  cursor: pointer;
	  
		  &:checked ~ .checkmark {
			background-color: $color-nm-first;
			border: 2px solid $color-nm-first;
		  }
	  
		  &:checked ~ .checkmark:after {
			display: block;
		  }
		}
	  
		.checkmark {
		  position: absolute;
		  top: 0;
		  left: 0;
		  height: 25px;
		  width: 25px;
		  border: 2px solid $gray-300;
		  border-radius: 50%;
	  
		  &:after {
			content: "";
			position: absolute;
			display: none;
		  }
	  
		  &:after {
			left: 6px;
			top: 2px;
			width: 9px;
			height: 13px;
			border: solid white;
			border-width: 0 3px 3px 0;
			transform: rotate(45deg);
		  }
		}
	  
		&:hover input ~ .checkmark {
		  background-color: $gray-300;
		  border: 2px solid $gray-500;
		}
	}

	&__price-summary {
        font-weight: 700;
        font-size: 16px;

        span {
            font-size: 24px;
        }
    }

    &__final {
        &-wrapper {
            text-align: center;
            padding: 30px;
            border: 1px solid $gray-300;
            border-radius: 5px;
        }

        &-info {
            margin-top: 20px;
            margin-bottom: 20px;
            background: $color-nm-first;
            padding: 30px;
            color: white;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                font-size: 18px;
                font-weight: 700;
                line-height: 1.3;
            }
        }


        &-btns {

            margin-top: 20px;

            .btn {
                display: block;

                @media (min-width: 768px) {
                    display: inline-block;
                }

                &:first-child {
                    margin-bottom: 5px;

                    @media (min-width: 768px) {
                        margin-right: 5px;
                        margin-bottom: 0;
                    }
                }

                &:last-child {
                    @media (min-width: 768px) {
                        margin-left: 5px;
                    }
                }
            }
        }

        &-icon {
            width: 150px;
            height: 150px;
        }
    }
	&__next-step {
        margin-top: 20px;
        padding-left: 0;

        li {
            position: relative;
            list-style: none;
            font-size: 16px;
            color: $gray-600;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            padding-left: 70px;
            min-height: 50px;

            .icon {
                position: absolute;
                left: 0;
                width: 50px;
                height: 50px;
                background: $color-nm-first;
                padding: 10px;
                border-radius: 50px;
                margin-right: 20px;
            }
        }
    }
	.btn {
		&--primary {
		  background: $color-nm-first;
		  color: white;
		  border-color: $color-nm-first;
		}
	  
		&--secondary {
		  background: $gray-300;
		  color: white;
		  border-color: $gray-300;
		}
	  }	
}
.clients-opinions {
    display: flex;
    justify-content: space-around;
    align-items: start;
    width: 100%;
	flex-wrap: wrap;
	.opinion {
		border:2px solid $color-nm-first;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		width: 250px;
		height:200px;
		position: relative;
		margin-top: 50px;
		margin-bottom: 140px;

		&::before {position: absolute;
		content: ' ';
			background-image: url('../assets/img/register/cudzyslow.svg');
			background-size: cover;
			background-repeat: no-repeat;
		
			width: 40px;
			height: 40px;
			background-color: #fff;
			top:-20px;
			left:-20px;
			font-size: 40px;
			text-align: center;
			}
		&::after {position: absolute;
			content: ' ';
			background-image: url('../assets/img/register/cudzyslow.svg');
			background-size: cover;
			background-repeat: no-repeat;
			width: 40px;
			height: 40px;
			background-color: #fff;
			bottom: -20px;
			right:-20px;
			font-size: 40px;
    		text-align: center;
		}

		.text {
			text-align: center;
			margin-top: 100px;
			font-size: 18px;
			padding-left: 15px;
			padding-right: 15px;
			line-height: 22px;
			padding-bottom: 20px;
			padding-top: 35px;
			min-height: 125px;
			display: flex;
			align-items: center;
		}
		.photo {
			color:$color-nm-first;
			text-align: center;
			img {
				border:2px solid $color-nm-first;
				border-radius:10px;
				overflow: hidden;
				width: 128px;
				height: 128px;
			}
		}
	}
}
/* .register-bottom {
	position: relative;
	&::after {
		content: "";
		position: absolute;
		background-image: url("../assets/img/register/fala-lodka.svg");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: bottom;
		width: 100%;
		height: 380px;
		bottom: 0;
	}

} */
.row-fala {
	position: relative;
	&::after {
		content: "";
		position: absolute;
		background-image: url("../assets/img/register/fala_1.svg");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: bottom;
		width: 100%;
		height: 220px;
		left: 0;
		right: 0;
		top: -47px;
		z-index: 0;
	}
}

.text-about-teams {
	width:100%;
	text-align: center;
    padding-top: 90px;
    padding-bottom: 60px;
	position: relative;
	z-index: 1;
	.text-first {
		color: $color-nm-first;
		font-size: 22px;
		font-weight: bold;
		line-height: 24px;
		margin-bottom: 5px;		
	}
	.text-second {
		color:$color-nm-second;

    font-size: 20px;
    font-weight: bold;
	}
}
.teams {
    display: flex;
    justify-content: space-around;
    align-items: start;
    width: 100%;
	margin-bottom: 50px;
    margin-top: -60px;
	flex-wrap: wrap;
	.team {
		font-size: 16px;
		text-align: center;
		img{max-width:128px;margin:0 auto 10px;border-radius:50%; border:2px solid $color-nm-first;}
		h4 {
			font-weight: bold;
			text-transform: uppercase;
			margin-bottom: 0;
			color: $color-nm-first;
		}
		a {font-size:16px; display: block;color: #23282c;
			&:hover {
				color: $color-nm-first;
			}
			}
		
	}
}
.round-ask-question {
	@media (min-width: 992px) {
		position: absolute;
		right: 25px;
		top: 80px;
	}
	width: 180px;
	height: 180px;
	border-radius: 50%;
    background-color: $color-nm-first;
    color: #fff;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 18px;
    line-height: 22px;
	z-index: 1;
	margin: 0px auto;
	.button-ask {
		background-color: #fff;
		color: $color-nm-first;
		font-size: 12px;
		padding: 5px;
		width: 105px;
		margin-top:5px;
		text-transform: uppercase;
		font-weight: bold;
    	line-height: 16px;
	}
}

.button-choice {
	color: #fff;
	border-radius: 0;
    font-weight: 700;
    padding: 10px 20px;	
	background-color: #FCBE22;
	width:100%;
	font-size: 22px;
	@media (min-width:992px) {
		max-width: 500px;
	}
	&.is-choice,&:hover {
		background-color: #9D9D9C;
	}
}
.border-bottom-null {
	@media (min-width:992px) {
		border-bottom: 0;
	}
}
.bg-white {background-color: #fff;}